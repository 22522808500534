import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AddCarExtras.css';
import bt from './commonStyle.css';
import { deleteListExtra, createOrUpdateListExtra } from '../../lib/graphql/listing'
import history from '../../core/history';

// Components
import Link from '../../components/Link';

// Locale
import messages from '../../locale/messages';
import makeGraphQLRequest  from '../../helpers/apiHelpers';

class AddCarExtras extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    formatMessage: PropTypes.func,
    extras: PropTypes.array,
    listId: PropTypes.number,
    user: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      extras: props.extras,
      listId: props.listId,
      showModal: false,
      editItemId: null,
      newName: '',
      newPrice: ''
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.newName !== this.props.newName || nextProps.newPrice !== this.props.newPrice) {
      return false;  
    }
    return true;
  }

  handleShow = (item = null) => {
    this.setState({
      showModal: true,
      editItemId: item ? item.id : null,
      newName: item ? item.name : '',
      newPrice: item ? parseFloat(item.price) : ''
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  

  handleSave = async () => {
    const { extras, editItemId, newName, newPrice, listId } = this.state;
    try {
      if (newName && newPrice) {
        if (editItemId) {
         
          const resp = await fetch('/graphql', {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              query: createOrUpdateListExtra,
              variables: {
                id: editItemId,
                listId,
                name: newName,
                price: parseFloat(newPrice),
              }
            }),
          }
        );
        
          const { data } = await resp.json();
  
          if (data.createOrUpdateListExtra.status === 200 && data.createOrUpdateListExtra.listExtra) {
            // Update existing item
            this.setState({
              extras: extras.map(extra =>
                extra.id === editItemId
                  ? { ...extra, name: newName, price: parseFloat(newPrice) }
                  : extra
              ),
              showModal: false,
              editItemId: null,
              newName: '',
              newPrice: '',
            });
          } else {
            // Handle error
            console.error('Failed to update extra:', data.createOrUpdateListExtra.errorMessage);
            // Optionally, you can show an error message to the user here
          }
        } else { 
          console.log('editItemId', editItemId); 
          const resp = await fetch('/graphql', {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              query: createOrUpdateListExtra,
              variables: {
                id: null,
                listId,
                name: newName,
                price: parseFloat(newPrice),
              }
            }),
          });
          
          const { data } = await resp.json();
  
          if (data.createOrUpdateListExtra.status === 200 && data.createOrUpdateListExtra.listExtra) {
            // Add new item
            this.setState({
              extras: [...extras, { id: data.createOrUpdateListExtra.listExtra.id, listId, name: newName, price: parseFloat(newPrice) }],
              showModal: false,
              newName: '',
              newPrice: '',
            });
          } else {
            // Handle error
            console.error('Failed to create extra:', data.createOrUpdateListExtra.errorMessage);
            // Optionally, you can show an error message to the user here
          }
        }
      }
    } catch (error) {
      console.error('Error creating or updating extra:', error);
    }
  };

  handleDelete = async (id) => {
    try {
      const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: deleteListExtra,
          variables: { id }
        }),
      });
      const { data } = await resp.json();

      if (data.deleteListExtra.status === 200 && data.deleteListExtra.deleted === true) {
        this.setState(prevState => ({
          extras: prevState.extras.filter(extra => extra.id !== id)
        }));

        window.location.reload();
      } else {
        // Handle error
        console.error('Failed to delete extra:', data.deleteListExtra.errorMessage);
        // Optionally, you can show an error message to the user here
      }
    } catch (error) {
      console.error('Error deleting extra:', error);
      // Optionally, you can show an error message to the user here
    }
  };

  handleClose = () => {
    this.setState({
      showModal: false,
      editItemId: null,
      newName: '',
      newPrice: ''
    });
  };

  previousPage() {
		const { listId } = this.props;
		const pathName = `/become-a-owner/${listId}/home`;
		history.push(pathName);
		this.setState({ page: 'home' }); // Asegúrate de que 'home' sea el valor correcto aquí.
	}

  render() {
    const { listId } = this.state;
    const { siteName } = this.props;
    const { extras, showModal, editItemId, newName, newPrice } = this.state;

    return (
      <div className={s.container}>
        <div className={s.gridFluid}>
          <div className={cx(s.space8, s.spaceTop8)}>
            <div className={cx(bt.textAlignCenter, bt.spaceBottom3)}>
              <h2>Car Extras</h2>
              <p className={cx(bt.commonContentText, bt.spaceBottom4, bt.textAlingJustify)}>
                <FormattedMessage {...messages.AddCarExtrasParrafo} />
              </p>
             
              <div className={s.extrasList}>
                {extras.length > 0 && extras.map((extra) => (
                  <div key={extra.id} className={s.extraItem}>
                    <span className={s.extraName}>{extra.name}</span>
                    <span className={s.extraPrice}>${extra.price.toFixed(2)}</span>
                    <button
                      onClick={() => this.handleShow(extra)}
                      className={cx(bt.btnPrimaryBorder, s.editButton)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => this.handleDelete(extra.id)}
                      className={cx(bt.btnPrimary, s.deleteButton)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
              <button onClick={() => this.handleShow()} className={cx(bt.btnPrimary, s.addButton)}>
                Agregar Extra
              </button>
              <button onClick={() => this.previousPage()} className={cx(bt.btnPrimaryBorder, s.backButton)}>
                Volver
              </button>

              {showModal && (
                <div className={s.modal}>
                  <div className={s.modalContent}>
                    <h3>{editItemId ? 'Edit Extra' : 'Add New Extra'}</h3>
                    <form>
                      <div className={bt.formGroup}>
                        <label>Nombre</label>
                        <input
                          type="text"
                          name='newName'
                          value={newName}
                          onChange={this.handleChange}
                          placeholder='Nombre del extra'
                          className={bt.formControlInput}
                          
                        />
                      </div>
                      <div className={bt.formGroup}>
                        <label>Precio</label>
                        <input
                          type="number"
                          name='newPrice'
                          value={newPrice}
                          placeholder='Precio del extra'
                          onChange={this.handleChange}
                          className={bt.formControlInput}
                          
                        />
                      </div>
                    </form>
                    <div>
                      <button onClick={this.handleClose} className={bt.btnPrimaryBorder}>Cancelar</button>
                      <button onClick={this.handleSave} className={bt.btnPrimary}>{editItemId ? 'Actualizar' : 'Agregar'}</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  user: state.user
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AddCarExtras)));

export function getSubject(type) {
	let subject, previewText;

	if(type === 'welcomeEmail' || type === 'confirmEmail' ) {
		subject = 'Por favor confirma tu dirección de correo electrónico';
		previewText = '¡Acción Requerida! Confirma tu correo electrónico';
	}
	if(type === 'bookingRequest') {
		subject = 'Tienes un nuevo viaje';
		previewText = '¡Buenas noticias! Tienes un nuevo viaje';
	}
	if(type === 'bookingRequestGuest') {
		subject = 'Tu solicitud de viaje ha sido enviada a tu propietario';
		previewText = '¡Buenas noticias! Tu viaje ha sido compartido con el propietario';
	}
	if(type === 'bookingConfirmedToHost') {
		subject = 'Has confirmado un viaje';
		previewText = 'Detalles de la reserva confirmada';
	}
	if(type === 'bookingConfirmedToGuest') {
		subject = 'Tu viaje ha sido confirmado por el propietario';
		previewText = '¡Prepara tu maleta, estás listo para tu viaje!';
	}
	if(type === 'bookingDeclinedToGuest') {
		subject = 'Tu solicitud de viaje ha sido rechazada por el propietario';
		previewText = 'Lo sentimos, tu solicitud ha sido rechazada';
	}
	if(type === 'bookingExpiredGuest') {
		subject = 'Tu solicitud de viaje ha caducado';
		previewText = 'Lo sentimos, tu solicitud ha caducado';
	}
	if(type === 'bookingExpiredHost') {
		subject = 'Tu viaje ha caducado';
		previewText = 'Tu viaje ha caducado';
	}
	if(type === 'cancelledByHost') {
		subject = 'Tu viaje ha sido cancelado por el propietario';
		previewText = 'Tu viaje ha sido cancelado';
	}
	if(type === 'cancelledByGuest') {
		subject = 'Tu viaje ha sido cancelado por el inquilino';
		previewText = 'Tu viaje ha sido cancelado';
	}
	if(type === 'completedGuest') {
		subject = 'Por favor escribe una reseña para tu propietario';
		previewText = '¡Acción Requerida! Escribe una reseña';
	}
	if(type === 'completedHost' ) {
		subject = 'Por favor escribe una reseña para tu inquilino';
		previewText = '¡Acción Requerida! Escribe una reseña';
	}
	if(type === 'forgotPasswordLink' ) {
		subject = 'Restablece tu contraseña';
		previewText = '¡Acción Requerida! Restablece tu contraseña';
	}
	if(type === 'message') {
		subject = 'Has recibido un nuevo mensaje';
		previewText = '¡Alguien te ha enviado un nuevo mensaje!';
	}
	if (type === 'inquiry') {
		subject = 'Has recibido una nueva consulta';
		previewText = '¡Alguien te ha enviado una consulta desde el formulario de contacto!';
	}
	if (type === 'documentVerification') {
		subject = 'Estado de verificación de documentos';
		previewText = 'Estado de verificación de documentos';
	}
	if (type === 'contact') {
		subject = 'Formulario de Contacto';
		previewText = 'Formulario de Contacto';
	}
	if (type === 'reportUser') {
		subject = 'Denunciando al Usuario';
		previewText = 'Formulario de Denuncia de Usuario';
	}
	if (type === 'bookingPreApproval') {
		subject = 'El propietario ha preaprobado tu solicitud';
		previewText = 'Reserva preaprobada';
	}
	if (type === 'banStatusServiceStatusBanned') {
		subject = 'Estado: Prohibido';
		previewText = 'Estado: Prohibido';
	}
	if (type === 'banStatusServiceStatusUnBanned') {
		subject = 'Estado: Desprohibido';
		previewText = 'Estado: Desprohibido';
	}
	if (type === 'contactSupport') {
		subject = 'Atención al Cliente';
		previewText = 'Atención al Cliente';
	}
	if (type === 'userFeedback') {
		subject = 'Comentarios de Clientes';
		previewText = 'Comentarios de Clientes';
	}

	return {
		subject,
		previewText
	};
}
require('dotenv').config();

/* eslint-disable max-len */

export const port = parseInt(process.env.PORT) || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `localhost:${port}`;
export const urlMail = 'https://www.hacelasimple.com';
export const url =  'http://localhost:3000';
export const sitename = 'HacelaSimple';

//  URL backend
export const backendUrl = 'http://localhost:4000';

//Socket URL
export const socketUrl = process.env.SOCKET_URL || 'http://localhost:4001';

export const socketPort = parseInt(process.env.SOCKET_PORT) || 4001;

export const graphQLPath = '/graphql';

// default locale is the first one
export const locales = ['en-US', 'es', 'it-IT', 'fr-FR', 'pt-PT', 'ar'];

export const databaseUrl = process.env.DATABASE_URL;

// Listing Photos Upload Directory
export const fileuploadDir = process.env.FILEUPLOAD_DIR || './images/upload/';

// Home Logo upload directory
export const homelogouploadDir = process.env.LOGOUPLOAD_DIR || './images/homeLogo/';

// Home page Banner upload directory
export const banneruploadDir = process.env.BANNER_UPLOAD_DIR || './images/banner/';
export const getBanneruploadDir = process.env.GET_BANNER_UPLOAD_DIR || '/images/banner/';

// User Profile Photos Upload Directory
export const profilePhotouploadDir = process.env.PROFILE_PHOTO_UPLOAD_DIR || './images/avatar/';

//Document Upload
export const documentuploadDir = process.env.FILEUPLOAD_DIR || './images/document/';

// Location upload directory
export const locationuploadDir = process.env.LOCATION_UPLOAD_DIR || './images/popularLocation/';

// Get Popular Location directory
export const getPopularLocationDir = process.env.GET_LOCATION_DIR || '/images/popularLocation/';

// Homepage images
export const homebanneruploadDir = process.env.HOME_BANNER_UPLOAD_DIR || './images/home/';
export const getHomebanneruploadDir = process.env.GET_HOME_PAGE_BANNER_DIR || '/images/home/';

// claim images
export const claimImagesUploadDir = process.env.CLAIM_IMAGES_UPLOAD_DIR || './images/claims/';
export const claimImageDir = process.env.CLAIM_IMAGE_DIR || '/images/claims/';

//favicon dir
export const faviconUploadDir = process.env.FAVICON_UPLOAD_DIR || './images/favicon/';
export const getFavIconDir = process.env.GET_FAVICON_DIR || '/images/favicon/';

// whyHostUploadDir	
export const whyHostUploadDir = process.env.WHYHOST_UPLOAD_DIR || './images/whyhost/';
export const getWhyHostDir = process.env.GET_WHYHOST_DIR || '/images/whyhost/';

// Logo upload directory
export const logouploadDir = process.env.LOGOUPLOAD_DIR || './images/logo/';
export const getLogoDir = process.env.GET_LOGO_DIR || '/images/logo/';

// OG Image Upload directory
export const ogImageuploadDir = process.env.OGIMAGEUPLOAD_DIR || './images/og-image/';

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: 'G-X7XXC7JYCQ',
  },
};

export const googleMapAPI = 'AIzaSyC-XK24qrbM8xGewB0aVU5_uIczQpcFCDM';
export const googleMapServerAPI = 'AIzaSyC-XK24qrbM8xGewB0aVU5_uIczQpcFCDM';

export const payment = {

  paypal: {
    returnURL: `${url}${process.env.PAYPAL_RETURN_URL}`,
    cancelURL: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    redirectURL: {
      success: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_CANCEL_URL}`
    },
    versions: {
      versionOne: '/v1',
      versionTwo: '/v2'
    },
    token_url: '/oauth2/token',
    payment_url: '/checkout/orders',
    capture_url: '/capture',
    payout_url: '/payments/payouts',
    refund: '/refund',
    refund_capture: '/payments/captures/'
  },

  stripe: {
    secretKey: process.env.STRIPE_SECRET, /* From ENV */
    publishableKey: 'pk_test_C5ukBJM7qr5P1F8dY4XKhdyp'
  },
 
  fiserv: {
    storename: process.env.STORE_NAME || '5924061301',
    //response_fail_url: 'http://localhost:3001/fiserv/fail',
    response_fail_url: `https://www.hacelasimple.com/fiserv/fail`,
    //response_success_url: 'http://localhost:3001/fiserv/success',
    response_success_url: `https://www.hacelasimple.com/fiserv/success`,
    //response_fee_fail_url: 'http://localhost:3001/fiserv/feefail',
    response_fee_fail_url: `https://www.hacelasimple.com/fiserv/feefail`,
    //response_fee_success_url: 'http://localhost:3001/fiserv/feesuccess',
    response_fee_success_url: `https://www.hacelasimple.com/fiserv/feesuccess`,
    hash_algorithm: process.env.HASH_ALGORITHM || 'HMACSHA256',
    checkout_option: process.env.CHECKOUT_OPTION || 'combinedpage',
    time_zone: process.env.TIME_ZONE || 'America/Buenos_Aires',
    gateway_url: process.env.GATEWAY_URL || 'https://test.ipg-online.com/connect/gateway/processing', 
    shared_secret: process.env.SHARED_SECRET || 'rYR@@M44ES',
    txntype: process.env.TXNTYPE || 'sale',
    fiserv_api: {
      apikey: process.env.FISERV_API_KEY || 'PyNwXkwfyVYDyFdRwSXyU0w0Pjh9H2oY',
      apiSecret: process.env.FISERV_API_SECRET || '88Os58YxtWss4eNwPw1UChnSYS2yvVZr5RJgFUl9beC',
      url: process.env.FISERV_API_URL || 'https://cert.api.firstdata.com/gateway/v2/payments',
      preAuth_type: process.env.FISERV_API_PREAUTH_TYPE || 'PaymentTokenPreAuthTransaction', // para ejecutar una preautorizacion tokenizada
      sale_type: process.env.FISERV_API_SALE_TYPE || 'PaymentTokenSaleTransaction', // para ejecutar una venta tokenizada
    }
  },

};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: '6LdF2lMpAAAAAEAnycke62xWAIrg-PHpgH-QJ3DK'
};

export const auth = {  jwt: { secret: process.env.JWT_SECRET || 'fQ1LIUex2uqWMKwX6FypAn7Pd5E8S93vgrskJT4NtHlRYDjhzOmCibZGa0VBoc'},

  redirectURL: {
    login: process.env.LOGIN_URL || '/dashboard',
    verification: process.env.LOGIN_URL || '/user/verification',
    userbanned: process.env.USER_BANNED_URL || '/userbanned',
    returnURLDeletedUser: process.env.DELETE_USER_URL || '/userbanned'
  },

  // https://developers.facebook.com/ IT IS REMOVED ON THE FUNCTIONING CODE. 
  facebook: {
    returnURL: process.env.FACEBOOK_CLIENT_URL || `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: process.env.GOOGLE_CLIENT_URL || `${url}/login/google/return`,
  }
};

export const coinbaseURL = process.env.COINBASE_URL;
export const cronTimezone = process.env.CRON_TIMEZONE;
